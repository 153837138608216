const module = {
  DASHBOARD: "Dashboard",
  USERS: "Users",
  ASSET: "Asset",
  ASSETTICKET: "Asset Tickets",
  VENDORS: "Vendors",
  SETTINGS: "Settings",
  PURCHASEREQUEST: "Purchase Request",
  REPORTS: "Asset Reports",
  ANNOUNCEMENTS: "Announcements",
};

const permissions = {
  VIEW: "view",
  FULL: "full",
  NONE: "none",
  ADD_UPDATE: "add_update",
};

const score = {
  [permissions.NONE]: 1,
  [permissions.VIEW]: 2,
  [permissions.ADD_UPDATE]: 3,
  [permissions.FULL]: 4,
};

const ROLES = {
  admin: "Admin",
  user: "User",
};

export { module, permissions, score, ROLES };
