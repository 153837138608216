import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Login from "../pages/auth/login";

//user
// import Dashboard from "../pages/user/dashboard";
// import Assets from "../pages/user/assets";
// import AssetsView from "../pages/user/assets/AssetsView";
// import AssetsCreate from "../pages/user/assets/AssetsCreate";

// import AssetsTickets from "../pages/user/assetsTickets";
// import AssetsTicketsView from "../pages/user/assetsTickets/AssetsTicketsView";
// import AssetsTicketsCreate from "../pages/user/assetsTickets/AssetsTicketsCreate";
// import AssetsTicketsEdit from "../pages/user/assetsTickets/AssetsTicketsEdit";

// admin
import AdminDashboard from "../pages/admin/dashboard";
import AdminAssets from "../pages/admin/assets";
import AdminAssetsCreate from "../pages/admin/assets/AssetsCreate";
import AdminAssetsEdit from "../pages/admin/assets/AssetsEdit";
import AdminAssetsView from "../pages/admin/assets/AssetsView";

import PurchaseRequest from "../pages/admin/purchaseRequest";
import PurchaseRequestCreate from "../pages/admin/purchaseRequest/PurchaseRequestCreate";
import PurchaseRequestEdit from "../pages/admin/purchaseRequest/PurchaseRequestEdit";
import PurchaseRequestView from "../pages/admin/purchaseRequest/PurchaseRequestView";

import AssetCategory from "../pages/admin/assetCategory";
import AssetCategoryView from "../pages/admin/assetCategory/AssetCategoryView";
import AssetCategoryCreate from "../pages/admin/assetCategory/AssetCategoryCreate";
import AssetCategoryEdit from "../pages/admin/assetCategory/AssetCategoryEdit";

import AdminAssetTickets from "../pages/admin/assetTickets";
import AdminAssetTicketsCreate from "../pages/admin/assetTickets/AssetTicketsCreate";
import AdminAssetTicketsEdit from "../pages/admin/assetTickets/AssetTicketsEdit";
import AdminAssetTicketsView from "../pages/admin/assetTickets/AssetTicketsView";

import Vendors from "../pages/admin/vendors";
import VendorCreate from "../pages/admin/vendors/VendorCreate";
import VendorEdit from "../pages/admin/vendors/VendorEdit";
import VendorView from "../pages/admin/vendors/VendorView";

import CompanyBranch from "../pages/admin/companyBranch";
import CompanyBranchCreate from "../pages/admin/companyBranch/CompanyBranchCreate";
import CompanyBranchEdit from "../pages/admin/companyBranch/CompanyBranchEdit";
import CompanyBranchView from "../pages/admin/companyBranch/CompanyBranchView";

import AssetReports from "../pages/admin/assetReports";
import ReportDetails from "../pages/admin/assetReports/ReportDetails";

import PageNotFound from "../components/common/PageNotFound";

import ProtectedRoute from "./protectedRoute";
import getTokenFromCookies from "../utils/token";
import UserView from "../pages/admin/users/userView";
import Users from "../pages/admin/users";
import ReleaseNotes from "../pages/admin/ReleaseNotes";
import { getUserRole } from "../utils/helper";
import PublicRouteWrapper from "../components/common/PublicRouteWrapper";
import AssetTicketDetails from "../pages/admin/assetReports/AssetTicketDetail";
import Settings from "../pages/admin/settings";
import ActivitySetting from "../pages/admin/settings/ActivitySetting";
import {
  updateAssetTicket,
  updateAssets,
  updateRequest,
  updateSettings,
  updateVendors,
  viewAssetTicket,
  viewAssets,
  viewDashboard,
  viewReports,
  viewRequest,
  viewSettings,
  viewUsers,
  viewVendors,
  fullAnnouncements,
} from "../utils/permissions";
import DisplayAnnouncements from "../pages/announcements";

const Roots = () => {
  const token = getTokenFromCookies();
  //const [cookies] = useCookies();
  // eslint-disable-next-line
  //const [user, setUser] = useState(null);
  const role = getUserRole();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const userData = await getuserDataFromCookies();
  //     setUser(userData);
  //   };
  //   fetchData();
  // }, [cookies]);

  return (
    <Routes>
      <Route
        path="/signin"
        element={
          <PublicRouteWrapper>
            <Login />
          </PublicRouteWrapper>
        }
      />
      <Route
        path="/"
        element={
          !token ? (
            <Login />
          ) : role === "user" ? (
            <Navigate to="/assets" />
          ) : (
            <Navigate to="/dashboard" />
          )
        }
      />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute
            key="dashboard"
            permission={viewDashboard}
            component={<AdminDashboard />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/assets"
        element={
          <ProtectedRoute
            key="assets"
            permission={viewAssets}
            component={<AdminAssets />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/assets/create"
        element={
          <ProtectedRoute
            key="asset-create"
            permission={updateAssets}
            component={<AdminAssetsCreate />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/assets/edit/:id"
        element={
          <ProtectedRoute
            key="asset-edit"
            permission={updateAssets}
            component={<AdminAssetsEdit />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/assets/view/:id"
        element={
          <ProtectedRoute
            key="asset-view"
            permission={viewAssets}
            component={<AdminAssetsView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/purchase-request"
        element={
          <ProtectedRoute
            key="purchase-request"
            permission={viewRequest}
            component={<PurchaseRequest />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/purchase-request/create"
        element={
          <ProtectedRoute
            key="create-purchase"
            permission={updateRequest}
            component={<PurchaseRequestCreate />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/purchase-request/edit/:id"
        element={
          <ProtectedRoute
            key="update-purchase"
            permission={updateRequest}
            component={<PurchaseRequestEdit />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/purchase-request/view/:id"
        element={
          <ProtectedRoute
            key="view-purchase"
            permission={viewRequest}
            component={<PurchaseRequestView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-category"
        element={
          <ProtectedRoute
            key="asset-category"
            permission={viewSettings}
            component={<AssetCategory />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-category/view/:id"
        element={
          <ProtectedRoute
            key="asset-category"
            permission={viewSettings}
            component={<AssetCategoryView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-category/create"
        element={
          <ProtectedRoute
            key="create-asset-category"
            permission={updateSettings}
            component={<AssetCategoryCreate />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-category/edit/:id"
        element={
          <ProtectedRoute
            key="asset-category-edit"
            permission={updateSettings}
            component={<AssetCategoryEdit />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-tickets"
        element={
          <ProtectedRoute
            key="asset-ticket"
            permission={viewAssetTicket}
            component={<AdminAssetTickets />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-tickets/create"
        element={
          <ProtectedRoute
            key="create-asset-ticket"
            permission={updateAssetTicket}
            component={<AdminAssetTicketsCreate />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-tickets/edit/:id"
        element={
          <ProtectedRoute
            key="edit-asset-ticket"
            permission={updateAssetTicket}
            component={<AdminAssetTicketsEdit />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/asset-tickets/view/:id"
        element={
          <ProtectedRoute
            key="view-asset-ticket"
            permission={viewAssetTicket}
            component={<AdminAssetTicketsView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/vendors"
        element={
          <ProtectedRoute
            key="vendors"
            permission={viewVendors}
            component={<Vendors />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/vendors/create"
        element={
          <ProtectedRoute
            key="create-vendor"
            permission={updateVendors}
            component={<VendorCreate />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/vendors/edit/:id"
        element={
          <ProtectedRoute
            key="edit-vendor"
            permission={updateVendors}
            component={<VendorEdit />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/vendors/view/:id"
        element={
          <ProtectedRoute
            key="view-vendor"
            permission={viewVendors}
            component={<VendorView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/branches"
        element={
          <ProtectedRoute
            key="branches"
            permission={viewSettings}
            component={<CompanyBranch />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute
            key="notifications"
            permission={viewSettings}
            component={<ActivitySetting />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute
            key="users"
            permission={viewUsers}
            component={<Users />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/users/:userId/view"
        element={
          <ProtectedRoute
            key="view-users"
            permission={viewUsers}
            component={<UserView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/branch/create"
        element={
          <ProtectedRoute
            key="create-branches"
            permission={updateSettings}
            component={<CompanyBranchCreate />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/branch/edit/:id"
        element={
          <ProtectedRoute
            key="edit-branches"
            permission={updateSettings}
            component={<CompanyBranchEdit />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/branch/view/:id"
        element={
          <ProtectedRoute
            key="view-branches"
            permission={viewSettings}
            component={<CompanyBranchView />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <ProtectedRoute
            key="reports"
            permission={viewReports}
            component={<AssetReports />}
          ></ProtectedRoute>
        }
      />
      <Route
        path="/reports/asset"
        element={
          <ProtectedRoute
            key="report-asset"
            permission={viewReports}
            component={<ReportDetails />}
          ></ProtectedRoute>
        }
      />

      <Route
        path="/reports/assets-ticket"
        element={
          <ProtectedRoute
            key="asset-ticket-report"
            permission={viewReports}
            component={<AssetTicketDetails />}
          ></ProtectedRoute>
        }
      />

      <Route
        path="/release-notes"
        element={
          <ProtectedRoute
            key="release-notes"
            permission={{}}
            component={<ReleaseNotes />}
          ></ProtectedRoute>
        }
      />

      <Route
        path="/announcements"
        element={
          <ProtectedRoute
            key="announcements"
            permission={fullAnnouncements}
            component={<DisplayAnnouncements />}
          ></ProtectedRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <ProtectedRoute
            key="settings"
            permission={viewSettings}
            component={<Settings />}
          ></ProtectedRoute>
        }
      />

      <Route path="*" element={<Navigate to="/404" />} />
      <Route
        path="/404"
        element={
          // <Layout>
          <PageNotFound />
          // </Layout>
        }
      />
    </Routes>
  );
};

export default Roots;
