import { module, permissions } from "../constants/constant";

const viewDashboard = {
  [module.DASHBOARD]: permissions.VIEW,
};

const fullUsers = {
  [module.USERS]: permissions.FULL,
};

const updateUsers = {
  [module.USERS]: permissions.ADD_UPDATE,
};
const viewUsers = {
  [module.USERS]: permissions.VIEW,
};

const fullAsset = {
  [module.ASSET]: permissions.FULL,
};

const updateAssets = {
  [module.ASSET]: permissions.ADD_UPDATE,
};
const viewAssets = {
  [module.ASSET]: permissions.VIEW,
};

const fullAssetCategory = {
  [module.ASSETCATEGORY]: permissions.FULL,
};

const updateAssetCategory = {
  [module.ASSETCATEGORY]: permissions.ADD_UPDATE,
};
const viewAssetCategory = {
  [module.ASSETCATEGORY]: permissions.VIEW,
};

const fullAssetTicket = {
  [module.ASSETTICKET]: permissions.FULL,
};

const updateAssetTicket = {
  [module.ASSETTICKET]: permissions.ADD_UPDATE,
};
const viewAssetTicket = {
  [module.ASSETTICKET]: permissions.VIEW,
};

const fullVendors = {
  [module.VENDORS]: permissions.FULL,
};

const updateVendors = {
  [module.VENDORS]: permissions.ADD_UPDATE,
};
const viewVendors = {
  [module.VENDORS]: permissions.VIEW,
};

const fullBranches = {
  [module.BRANCHES]: permissions.FULL,
};

const updateBranches = {
  [module.BRANCHES]: permissions.ADD_UPDATE,
};
const viewBranches = {
  [module.BRANCHES]: permissions.VIEW,
};

const fullSettings = {
  [module.SETTINGS]: permissions.FULL,
};

const updateSettings = {
  [module.SETTINGS]: permissions.ADD_UPDATE,
};
const viewSettings = {
  [module.SETTINGS]: permissions.VIEW,
};

const fullRequest = {
  [module.PURCHASEREQUEST]: permissions.FULL,
};

const updateRequest = {
  [module.PURCHASEREQUEST]: permissions.ADD_UPDATE,
};
const viewRequest = {
  [module.PURCHASEREQUEST]: permissions.VIEW,
};

const viewReports = {
  [module.REPORTS]: permissions.VIEW,
};

const fullAnnouncements = {
  [module.ANNOUNCEMENTS]: permissions.FULL,
};

export {
  viewDashboard,
  fullUsers,
  updateUsers,
  viewUsers,
  fullAsset,
  updateAssets,
  viewAssets,
  fullAssetCategory,
  updateAssetCategory,
  viewAssetCategory,
  fullAssetTicket,
  updateAssetTicket,
  viewAssetTicket,
  fullBranches,
  viewBranches,
  updateBranches,
  fullVendors,
  viewVendors,
  updateVendors,
  viewReports,
  fullSettings,
  updateSettings,
  viewSettings,
  viewRequest,
  fullRequest,
  updateRequest,
  fullAnnouncements,
};
